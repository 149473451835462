// Generated by Framer (e39ee10)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["edmSC2ZZM", "bE5LD5oPe", "seDXfQYty"];

const serializationHash = "framer-J46no"

const variantClassNames = {bE5LD5oPe: "framer-v-1dmhf9v", edmSC2ZZM: "framer-v-bdgylk", seDXfQYty: "framer-v-140w407"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition2 = {delay: 0.35, duration: 0.7, ease: [0.7, 0.27, 0.26, 0.91], type: "tween"}

const animation = {opacity: 0, rotate: 360, rotateX: 0, rotateY: 0, scale: 1.4, skewX: 0, skewY: 0, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Accent: "bE5LD5oPe", Neutral: "seDXfQYty", White: "edmSC2ZZM"}

const getProps = ({height, icon, id, width, ...props}) => { return {...props, E9UdMMRL9: icon ?? props.E9UdMMRL9, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "edmSC2ZZM"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, E9UdMMRL9, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "edmSC2ZZM", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "bE5LD5oPe") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-bdgylk", className, classNames)} data-framer-name={"White"} layoutDependency={layoutDependency} layoutId={"edmSC2ZZM"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 1200, borderBottomRightRadius: 1200, borderTopLeftRadius: 1200, borderTopRightRadius: 1200, ...style}} variants={{bE5LD5oPe: {backgroundColor: "var(--token-7f4773cc-059e-4853-9683-4a2541549ef2, rgb(86, 219, 86))"}, seDXfQYty: {backgroundColor: "var(--token-d219888e-aed7-490c-8839-b35318192e86, rgb(231, 240, 231))"}}} {...addPropertyOverrides({bE5LD5oPe: {"data-framer-name": "Accent"}, seDXfQYty: {"data-framer-name": "Neutral"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "48px", ...toResponsiveImage(E9UdMMRL9), ...{ positionX: "center", positionY: "center" }}} className={"framer-13rlfbc"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"E__5ElqWI"}/>{isDisplayed() && (<MotionDivWithFX __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"loop"} __framer__loopTransition={transition2} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={"framer-18b8s1u"} data-framer-name={"Glow"} layoutDependency={layoutDependency} layoutId={"BCNQvwBtL"} style={{backgroundColor: "rgba(86, 219, 86, 0.57)", borderBottomLeftRadius: 120, borderBottomRightRadius: 120, borderTopLeftRadius: 120, borderTopRightRadius: 120}}/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-J46no.framer-3dvjt7, .framer-J46no .framer-3dvjt7 { display: block; }", ".framer-J46no.framer-bdgylk { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 24px; height: min-content; justify-content: flex-start; overflow: visible; padding: 24px; position: relative; width: min-content; }", ".framer-J46no .framer-13rlfbc { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 48px); overflow: hidden; position: relative; width: 48px; }", ".framer-J46no .framer-18b8s1u { flex: none; height: 96px; left: calc(50.00000000000002% - 96px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 96px / 2); width: 96px; will-change: var(--framer-will-change-override, transform); z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-J46no.framer-bdgylk { gap: 0px; } .framer-J46no.framer-bdgylk > * { margin: 0px; margin-left: calc(24px / 2); margin-right: calc(24px / 2); } .framer-J46no.framer-bdgylk > :first-child { margin-left: 0px; } .framer-J46no.framer-bdgylk > :last-child { margin-right: 0px; } }", ".framer-J46no.framer-v-1dmhf9v .framer-13rlfbc { z-index: 2; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 96
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"bE5LD5oPe":{"layout":["auto","auto"]},"seDXfQYty":{"layout":["auto","auto"]}}}
 * @framerVariables {"E9UdMMRL9":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermRRqoiGmx: React.ComponentType<Props> = withCSS(Component, css, "framer-J46no") as typeof Component;
export default FramermRRqoiGmx;

FramermRRqoiGmx.displayName = "Icon Circle";

FramermRRqoiGmx.defaultProps = {height: 96, width: 96};

addPropertyControls(FramermRRqoiGmx, {variant: {options: ["edmSC2ZZM", "bE5LD5oPe", "seDXfQYty"], optionTitles: ["White", "Accent", "Neutral"], title: "Variant", type: ControlType.Enum}, E9UdMMRL9: {title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FramermRRqoiGmx, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})